<template>
  <div
    class="tree-selector"
    :style="cssStyle"
    @click.stop="toggleMenu"
    v-clickoutside="handleClose"
  >
    <div class="tree-selector-hot-zone">
      <!-- 单选 -->
      <div class="single-select" v-if="!multiple">
        <el-input
          v-model="singleSelect"
          suffix-icon="el-icon-arrow-down"
          @click.native="clickHandler"
          placeholder="Категори сонгох"
        ></el-input>
        <el-input
          ref="singleSelectSearchRef"
          v-model="singleSelectSearch"
          :suffix-icon="popShow ? 'el-icon-arrow-up' : 'el-icon-arrow-down'"
          :placeholder="singleSelect"
          :style="singleSelectStyle"
        ></el-input>
      </div>
      <!-- 多选 -->
      <div
        class="selected-node-item-box"
        v-else
        :style="{ display: collapseTags ? 'flex' : 'block' }"
      >
        <span class="placeholder" v-show="tags.length == 0">Категори сонгох</span>
        <em
          class="el-icon-arrow-down"
          :style="{ transform: popShow ? 'rotateZ(180deg)' : '' }"
        ></em>
        <!-- 展示全部选择标签 -->
        <template v-if="!collapseTags">
          <el-tag
            class="selected-node-item"
            closable
            size="small"
            v-for="tag in tags"
            :key="tag[treeNodeKey]"
            disable-transitions
            @close="handlerTagClose(tag[treeNodeKey])"
          >
            <el-tooltip
              :enterable="false"
              :content="tag[defaultProps.label]"
              placement="bottom-start"
            >
              <span> {{ tag[defaultProps.label] }}</span>
            </el-tooltip>
          </el-tag>
        </template>
        <!-- 只展示一个标签+选中的数量 -->
        <template v-else-if="tags.length > 0">
          <el-tag
            class="selected-node-item"
            style="max-width: calc(100% - 55px)"
            closable
            size="small"
            disable-transitions
            @close="handlerTagClose(tags[0][treeNodeKey])"
          >
            <el-tooltip
              :enterable="false"
              :content="tags[0][defaultProps.label]"
              placement="bottom-start"
            >
              <span> {{ tags[0][defaultProps.label] }}</span>
            </el-tooltip>
          </el-tag>
          <el-tag class="selected-node-item" size="small" disable-transitions>
            {{ `+ ${tags.length}` }}
          </el-tag>
        </template>
      </div>
    </div>
    <!-- 下拉树 -->
    <div class="tree-pop" v-show="popShow">
      <div class="tree-box">
        <el-input
          v-model="searh"
          v-if="filter && multiple"
          prefix-icon="el-icon-search"
          placeholder="Категори хайх"
          clearable
          @click.native.prevent.stop
        ></el-input>
        <el-scrollbar @click.native.prevent.stop>
          <el-tree
            ref="tree"
            :node-key="treeNodeKey"
            :data="treeData"
            :props="defaultProps"
            :filter-node-method="filterNode"
            :default-expand-all="defaultExpandAll"
            :default-expanded-keys="defaultExpandedKeys"
            :show-checkbox="multiple"
            :check-strictly="multiple"
            :default-checked-keys="treeValue"
            :highlight-current="!multiple"
            :current-node-key="currentNodeKey"
            :check-on-click-node="multiple"
            :expand-on-click-node="false"
            @node-click="handleNodeClick"
            @check-change="handCheckChange"
          ></el-tree>
        </el-scrollbar>
      </div>
    </div>
  </div>
</template>
<script>
import Clickoutside from 'element-ui/src/utils/clickoutside'
import helper from '../../helpers/helper'
export default {
  name: 'TreeSelector',
  componentName: 'TreeSelector',
  directives: { Clickoutside },
  model: {
    prop: 'value',
    event: 'update:modelValue'
  },
  props: {
    value: {
      type: [Number, String, Array]
    },
    treeData: {
      type: Array,
      default: () => []
    },
    treeNodeKey: {
      type: String,
      default: 'id'
    },
    defaultProps: {
      type: Object,
      default: () => {
        return {
          children: 'children',
          label: 'label'
        }
      }
    },
    defaultExpandAll: {
      type: Boolean,
      default: false
    },
    multiple: {
      type: Boolean,
      default: false
    },
    collapseTags: {
      type: Boolean,
      default: false
    },
    filter: {
      type: Boolean,
      default: true
    },
    width: {
      type: [String, Number],
      default: 200
    }
  },
  data () {
    return {
      singleSelectSearch: '',
      isShowFilter: false,
      searh: '',
      popShow: false
    }
  },
  computed: {
    modelValue () {
      return helper.isArray(this.value) ? this.value : [this.value]
    },
    // 树数据扁平化
    flattenTreeData () {
      return helper.flattenObjectArrayByPropName(this.treeData, this.defaultProps.children, undefined, true)
    },
    treeValue: {
      get: function () {
        if (this.multiple) {
          return this.modelValue
        }
        return this.modelValue.slice(0, 1)
      },
      set: function (value) {
        this.$emit('update:modelValue', value)
      }
    },
    // 树组件宽度
    cssStyle () {
      const elWidth = typeof this.width === 'number' ? `${this.width}px` : this.width
      return { width: elWidth }
    },
    // 初始化时，默认展开的树节点
    defaultExpandedKeys () {
      return this.flattenTreeData.filter(treeDataItem => this.modelValue.includes(treeDataItem[this.treeNodeKey]))
        .reduce((parentIds, current) => {
          if (current.parent) {
            parentIds = [...parentIds, current.parent[this.treeNodeKey]]
          }
          return parentIds
        }, [])
    },
    singleSelect () {
      const res = this.flattenTreeData.find(treeDataItem => this.treeValue.includes(treeDataItem[this.treeNodeKey]))
      return res ? res[this.defaultProps.label] : ''
    },
    singleSelectStyle () {
      return this.isShowFilter ? { zIndex: 1 } : {}
    },
    currentNodeKey () {
      return this.modelValue[0]
    },
    // 多选
    tags () {
      return this.flattenTreeData.filter(treeDataItem => this.treeValue.includes(treeDataItem[this.treeNodeKey]))
    }
  },
  watch: {
    searh (value) {
      this.$refs.tree.filter(value)
    },
    singleSelectSearch (value) {
      this.$refs.tree.filter(value)
    }
  },
  methods: {
    toggleMenu () {
      this.popShow = !this.popShow
      !this.popShow && (this.isShowFilter = false)
    },
    handleClose () {
      this.popShow = false
      this.isShowFilter = false
      this.singleSelectSearch = ''
    },
    // 筛选树节点
    filterNode (value, data) {
      if (!value) {
        return true
      }
      return data[this.defaultProps.label].toLowerCase().indexOf(value.toLowerCase()) !== -1
    },
    clickHandler () {
      if (!this.filter) {
        return
      }
      this.isShowFilter = true
      this.$refs.singleSelectSearchRef.focus()
    },
    // 树节点点击事件
    handleNodeClick (data) {
      if (this.multiple) {
        return
      }
      this.treeValue = [data[this.treeNodeKey]]
      this.isShowFilter = false
      this.singleSelectSearch = ''
    },
    // 标签关闭事件
    handlerTagClose (tagId) {
      const arr = this.treeValue
      arr.splice(arr.indexOf(tagId), 1)
      this.$refs.tree.setCheckedKeys(arr)
    },
    // 树节点复选框选中事件
    handCheckChange (data, isChecked) {
      if (!this.multiple) {
        return
      }
      const nodeKey = data[this.treeNodeKey]
      const arr = this.treeValue
      const index = arr.indexOf(nodeKey)
      if (isChecked) {
        this.treeValue = [...this.treeValue, nodeKey]
      } else if (index !== -1) {
        arr.splice(index, 1)
        this.treeValue = arr
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.tree-selector {
  position: relative;
  display: inline-block;
  // padding-bottom: 14px;
  user-select: none;
  .tree-selector-hot-zone {
    cursor: pointer;
    .single-select {
      position: relative;
      .el-input:last-child {
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
      }
    }
    .selected-node-item-box {
      position: relative;
      min-height: 28px;
      border: 1px solid #dcdfe6;
      border-radius: 4px;
      padding: 5px 25px 5px 10px;
      transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
      .placeholder {
        font-size: 14px;
        line-height: 28px;
        opacity: 0.5;
      }
      em.el-icon-arrow-down {
        position: absolute;
        right: 10px;
        top: calc(50% - 7px);
        transition: transform 0.3s;
      }
      .el-tag {
        display: flex;
        align-items: center;
        margin: 2px;
        max-width: calc(100% - 10px);
        overflow: hidden;
        text-overflow: ellipsis;
        ::v-deep .el-icon-close {
          top: 0;
        }
        span {
          display: inline-block;
          vertical-align: top;
          width: calc(100% - 16px);
          overflow: hidden;
          text-overflow: ellipsis;
          span {
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            &:last-child {
              width: auto;
            }
          }
        }
      }
    }
  }
  .tree-pop {
    // display: none;
    position: absolute;
    width: 100%;
    padding-top: 14px;
    z-index: 1;
  }
  .tree-box {
    padding: 6px 0 0;
    background: #fff;
    border: 1px solid #e4e7ed;
    border-radius: 4px;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    &::before {
      content: "";
      position: absolute;
      left: 50%;
      width: 10px;
      height: 10px;
      background: #fff;
      border: 1px solid #e4e7ed;
      border-right-color: transparent;
      border-bottom-color: transparent;
      transform: matrix(0.707107, 0.707107, -0.707107, 0.707107, -6, -13);
    }
    .el-input {
      width: calc(100% - 20px);
      margin: 0 10px 10px;
    }
    .el-tree {
      width: fit-content;
      min-width: 100%;
      max-height: 300px;
      // ::v-deep .el-tree-node__content {
      //   width: auto;
      // }
    }
  }
  &:hover {
    .selected-node-item-box {
      border-color: #409eff;
      outline: 0;
    }

    .tree-pop {
      // display: block;
      user-select: none;
    }
  }
}
</style>
